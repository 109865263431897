footer {
  background: #00353a;
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 3rem;
  font-size: 1.4rem;
  /* position: absolute; */
  /* bottom: 0; */
  color: rgb(14, 255, 155);
}

footer a {
  color: var(--color-primary);
}
