.tags {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 90%;
  /* justify-content: center; */
  flex-wrap: wrap;
  gap: 1.5rem;
}

.tags__item {
  border: 1px solid #fafafa;
  border-top: 1.6rem solid #000;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 1rem 2rem;
  min-height: 20rem;
}

@media (min-width: 768px) {
  .tags {
    flex-direction: row;
  }
  .tags__item {
    width: 48.9%;
  }
}

@media (min-width: 1024px) {
  .tags__item {
    width: 32%;
  }
}

.tag__name {
  color: var(--color-black);
  font-size: 1.7rem;
}

.tag__name h3 {
  margin-left: 0;
}

.btn-tag-follow {
  border-radius: var(--border-radius);
  background: #bdbdbd;
  text-transform: none;
  font-size: 1.5rem;
  font-weight: 500;
}

.btn-tag-follow:hover {
  background: #d6d6d7;
}

button.btn-following {
  background: #f5f5f5;
  color: var(--color-black);
  border: 2px solid #a3a3a3;
}

button.btn-following:hover {
  background: rgba(0, 0, 0, 0.035);
}
