*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-size: 1.4rem;
  color: var(--color-black);
  background: #efefef;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.6;
  overflow-x: hidden;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* position: relative; */
  min-height: 100vh;
}

html {
  font-size: 62.5%;
  /*1 rem = 10px, 10/16 * 100% = 62.5*/
}

:root {
  --color-primary: #3b49df;
  --color-black: #000;
  --color-white: #fff;
  --color-gray: #404040;
  --bg-primary: #f5f5f5;
  --bg-secondary: #fff;
  --btn-bg-secondary: #bdbdbd;
  --btn-bg-secondary-hover: #3d3d3d;
  --btn-bg-red: #b91c1b;
  --icon-color-like: #dc2625;
  --icon-color-unicorn: #169668;
  --icon-color-bookmark: #4f45e5;
  --icon-bg-hover-like: #f4e2e1;
  --icon-bg-hover-unicorn: #e1ece7;
  --icon-bg-hover-bookmark: #f5f5f5;
  --border-radius: 7px;
  --border-radius-big: 10px;
  --border-radius-circle: 50%;
  --border-color: #e5e5e5;
  --box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  --box-shadow-2: 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.btn,
.btn:link,
.btn:visited {
  text-decoration: none;
  padding: 1.2rem 2rem;
  border-radius: var(--border-radius);
  display: inline-block;
  font-size: 1.23rem;
  border: none;
  cursor: pointer;
}

@media (max-width: 56.25em) {
  .btn,
  .btn:link,
  .btn:visited {
    font-size: 1.2rem;
  }
}

.btn--hover:hover,
.btn--hover:focus {
  background: var(--btn-bg-secondary);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--color-black);
}

img {
  object-fit: cover;
}

.container-layout {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin: 0 auto;
  width: 100%;
  max-width: 128rem;
  background-color: #00ffa2;
}

.results__heading,
.reading-list__heading,
.tag-page__heading {
  margin: 2rem 1rem;
}

.container-search-results,
.reading-list,
.tag-page {
  margin: 0 auto;
  max-width: 80rem;
}

.reading-list p {
  margin-left: 1rem;
}

@media (min-width: 768px) {
  .container-layout {
    padding: 0 2rem;
  background-color: #00ffa2;

  }
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

form input,
form label {
  width: 100%;
  margin: 0;
  color: var(--color-black);
}

form input {
  padding: 1rem 2rem;
  margin-top: 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}
