/*============
RIGHT SIDEBAR
============*/

.sidebar--right {
  display: none;
  flex-direction: column;
  width: 50%;
}

.news,
.discuss,
.webdev {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid #efefef;
  border-radius: var(--border-radius);
}

.discuss,
.webdev {
  margin-top: 2rem;
}

.news h3,
.discuss h3,
.webdev h3 {
  border-bottom: 1px solid #efefef;
  padding: 0.5rem 1.5rem;
  margin-left: 0;
}

.post__item {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #efefef;
}

.post__item a {
  color: var(--color-black);
  font-size: 1.5rem;
}

@media (min-width: 1024px) {
  .sidebar--right {
    display: flex;
  }
}
