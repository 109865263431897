.container {
  margin-left: auto;
  margin-right: auto;
}

.container-nav {
  display: flex;
  max-width: 128rem;
  padding-top: 0.5rem;
  /* background-color: rgb(0, 155, 39); */
}
.pic{
  width: 30%;
  height: 5%;
  
}
/*==============
HEADER
==============*/

header {
  /* background: var(--color-white); */
  padding: 0.3rem 1rem;
  position: relative;
  margin-bottom: 1rem;
  /* max-height: 5.8rem; */
  background-color: rgb(0, 255, 0);
}

.header__logo {
  color: var(--color-black);
}

@media (min-width: 300px) {
  header {
    padding-right: 1rem;
  }
}

.header__hamburger-menu {
  background-color: var(--color-black);
  height: 2px;
  width: 1.6rem;
  cursor: pointer;
  margin-top: 2rem;
  margin-right: 1rem;
}

.header__hamburger-menu::before,
.header__hamburger-menu::after {
  content: '';
  background-color: var(--color-black);
  height: 2px;
  width: 1.6rem;
  cursor: pointer;
  display: block;
}

.header__hamburger-menu::before {
  transform: translateY(-6px);
}

.header__hamburger-menu::after {
  transform: translateY(4px);
}

.header__logo-search {
  display: flex;
  align-items: flex-start;
}

.search-bar,
.search-bar--mobile {
  background: var(--color-white);
  border: 1px solid rgb(192, 0, 0);
  padding: 1.1rem;
  margin: 0 1.7rem;
  border-radius: var(--border-radius);
}

.search-bar {
  display: none;
}

.search-bar--mobile {
  width: 99%;
  margin: 0.5rem;
}

/*==============
NAV
==============*/

.nav {
  display: flex;
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: auto;
}

.nav__list {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav__list a {
  min-width: 14rem;
}

.list__item--mobile {
  display: none;
}

.nav__list .nav__btn {
  background: transparent;
  padding: 0;
}

.login-link {
  color: var(--color-gray);
}

.item--create {
  border-radius: 7px;
  border: 1px solid var(--color-primary);
}

.create-link {
  color: var(--color-primary);
}

.item--create:hover,
.item--create:focus {
  background-color: var(--color-primary);
  text-decoration: underline;
}

.item--create:hover a,
.item--create:focus a {
  color: #fff;
}

.nav__list li:not(:first-child) {
  margin-left: 2rem;
}

.search-icon {
  cursor: pointer;
}

@media (min-width: 768px) {
  .list__item--mobile,
  .search-bar {
    display: inline-block;
  }

  .search-icon,
  .header__hamburger-menu {
    display: none;
  }

  .header__logo-search {
    width: 45%;
  }

  .search-bar {
    width: 100%;
  }

  .search-mobile-container {
    display: none;
  }
}

.list__item {
  padding: 0.8rem 1.1rem;
}

.list__item--notifs {
  padding: 0.3rem 0.5rem;
}

.nav__list .author__image {
  height: 3rem;
  width: 3rem;
}

.hvr-bg-lt:hover,
.hvr-bg-lt:focus {
  color: var(--color-primary);
  background-color: rgba(49, 62, 190, 0.2);
  text-decoration: underline;
  border-radius: var(--border-radius);
}

.item--create:hover,
.item--create:focus {
  background-color: var(--color-primary);
  color: #fff;
  text-decoration: underline;
}

.dropdown {
  background: var(--color-white);
  position: absolute;
  top: 6rem;
  right: 1rem;
  left: auto;
  border: 2px solid #fafafa;
  border-radius: var(--border-radius);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  min-width: 25rem;
  width: 100%;
  z-index: 999;
}

.dropdown--close {
  display: none;
}

@media (min-width: 640px) {
  .dropdown {
    width: auto;
  }
}

.dropdown__list li:not(:first-child) {
  margin-left: 0;
}

.dropdown__list li {
  padding: 1rem 2rem;
}

.dropdown__list li:first-child {
  border-bottom: 1px solid #fafafa;
  padding: 0.6rem 2rem;
}

.dropdown__list li:last-child {
  border-top: 1px solid #fafafa;
}

.dropdown__btn {
  background: transparent;
  font-size: 1.6rem;
  padding: 0;
}

.dropdown small {
  color: #404040;
}

.link--notifs-icon {
  position: relative;
}

.notif__counter {
  width: 5px;
  height: 5px;
  background-color: #666fcf;
  border-radius: 50%;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
}
