.tags__input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 1.2rem 0;
  padding: 1rem 1.2rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}
.tags__input:focus-within {
  border: 1px solid var(--color-primary);
}
.tags__input input {
  border: none;
  padding: 0.4rem 0 0 0.4rem;
}
.tags__input input:focus {
  outline: transparent;
}
.input__list {
  list-style: none;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.input__item {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  padding: 1rem;
  border-radius: 6px;
  background: var(--color-primary);
  gap: 0.8rem;
}
.input__item i {
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  color: var(--color-primary);
  border-radius: var(--border-radius-big);
  background: var(--color-white);
  cursor: pointer;
}
