.comments {
  display: flex;
  background: var(--color-white);
  border-radius: var(--border-radius-big);
  flex-direction: column;
  gap: 1.6rem;
  padding: 2.5rem 1.5rem;
}

.comment-form {
  display: flex;
  gap: 1.5rem;
}

.comment-form form {
  gap: 1rem;
}

.comment-form input {
  background: var(--color-white);
  flex-grow: 2;
  border: none;
  box-shadow: var(--box-shadow-2);
  padding: 2rem;
  margin-top: 0;
  font-size: 1.6rem;
}

.btn--comment {
  padding: 1.2rem;
  align-self: flex-start;
  background: var(--color-primary);
  color: var(--color-white);
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: none;
}

.comments__btn > * {
  margin-right: 1rem;
}

.container-comment {
  display: flex;
  gap: 1.5rem;
}

.author__image--comment {
  width: 3.5rem;
  height: 3.5rem;
}

.comment {
  width: 84%;
}

@media (min-width: 450px) {
  .comment {
    width: 92%;
  }
}

.comment__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.6rem;
  border-radius: var(--border-radius);
  padding: 1.2rem 2rem;
  margin-bottom: 0.5rem;
  box-shadow: var(--box-shadow-2);
}

.comment__author {
  font-weight: 600;
}

.comment__meta {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  gap: 1.5rem;
}

.comment__meta span {
  font-size: 1.3rem;
  align-self: center;
  font-style: italic;
}

.comment .preview__reactions {
  font-size: 1.25rem;
  margin-top: 0;
  gap: 2rem;
}

.preview__reactions--left div {
  cursor: pointer;
}

.preview__reactions--right {
  margin-right: 1rem;
}

.comments__total {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  background: transparent;
}

.comments__total > span {
  display: flex;
  gap: 1rem;
}

.replies,
.reply-form {
  margin-left: 1rem;
}

.replies .post__author-img {
  width: 2.5rem;
  height: 2.5rem;
}

.comment__liked i,
.comment__liked span {
  color: var(--icon-color-like);
}

.comment__liked {
  font-weight: 500;
  background: rgba(var(--icon-color-like), 0.3);
  background: #f4e2e1;
  border-radius: var(--border-radius);
  color: var(--icon-color-like);
}

@media (min-width: 600px) {
  .comment .preview__reactions {
    justify-content: flex-start;
    gap: 5rem;
  }
}

@media (min-width: 768px) {
  .comment .preview__reactions {
    margin-left: 1rem;
    margin-bottom: 1rem;
    width: 60%;
  }
}
