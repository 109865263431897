.about {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-bottom: 1rem;
  gap: 2rem;
  background: var(--color-white);
  border: 2px solid #efefef;
  border-radius: 7px;
}

.about__links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about__links a {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.hvr-underline:hover,
.hvr-underline:focus {
  text-decoration: underline;
}

.about__bold {
  font-weight: 800;
  font-size: 2rem;
  line-height: 1.3;
}

.about__bold--co {
  color: var(--color-primary);
}
